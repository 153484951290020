<template>
  <div v-if="question">
    <sqr-input-date
      :label="question.name"
      :value="answer"
      :required="question.required"
      @change="$emit('change', $event)"
      :disabled="disabled"
      :v="v"
    />
  </div>
</template>

<script>
import SqrInputDate from '@/sqrd-ui/SqrInputDate';
export default {
  name: 'ProductView',
  components: { SqrInputDate },
  props: {
    question: { type: Object },
    answer: { type: String },
    disabled: { type: Boolean },
    v: { type: Object },
  },
};
</script>
